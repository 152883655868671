import {TRANSLATION_NL} from 'src/environments/wave/translations/nl';
import {TRANSLATION_EN} from 'src/environments/wave/translations/en';
import {TRANSLATION_DE} from 'src/environments/wave/translations/de';
import {TRANSLATION_TR} from 'src/environments/wave/translations/tr';
import {TRANSLATION_PL} from 'src/environments/wave/translations/pl';
import {TRANSLATION_RO} from 'src/environments/wave/translations/ro';
import {TRANSLATION_PT} from 'src/environments/wave/translations/pt';
import {TRANSLATION_BG} from 'src/environments/wave/translations/bg';

export const environment = {
    production: false,
    id: 'nl.recognize.wave.test',
    name: 'WAVE',
    version: '4.6.1',
    author: {
        name: 'Koninklijke VolkerWessels N.V.',
        email: 'info@volkerwessels.com',
        website: 'https://www.volkerwessels.com/',
    },
    deprecation: {
        domains: [],
        date: '2099-09-01T00:00:00',
    },
    host: 'vwwave',
    storage: '__wave',
    initialTheme: '',
    color: {
        primary: '#32659C',
        statusBar: {
            default: '#336699',
            overlay: '#14293D',
        },
    },
    supportedLanguages: [
        {
            value: 'nl',
            label: 'Nederlands',
            translationFile: TRANSLATION_NL,
        },
        {
            value: 'de',
            label: 'Deutsch',
            translationFile: TRANSLATION_DE,
        },
        {
            value: 'en',
            label: 'English',
            translationFile: TRANSLATION_EN,
        },
        {
            value: 'tr',
            label: 'Türkçe',
            translationFile: TRANSLATION_TR,
        },
        {
            value: 'pl',
            label: 'Polsku',
            translationFile: TRANSLATION_PL,
        },
        {
            value: 'ro',
            label: 'Romana',
            translationFile: TRANSLATION_RO,
        },
        {
            value: 'pt',
            label: 'Portugues',
            translationFile: TRANSLATION_PT,
        },
        {
            value: 'bg',
            label: 'български',
            translationFile: TRANSLATION_BG,
        },
    ],
    api: 'https://vwwave.test.recognize.hosting',
    apiTimeout: 60000,
    msAuth: {
        tenantId: 'common',
        clientId: '525ca5b8-0421-4cb6-b4aa-1695f89fca42',
        keystoreHash: 'wkNJVXZDOiQIcWQHveQP8U0gTfE=',
        scopes: ['api://525ca5b8-0421-4cb6-b4aa-1695f89fca42/default'],
        redirectUri: 'https://vwwave-app.test.recognize.hosting',
    },
    deeplinks: [
        'https://vwwave.test.recognize.hosting/deeplink/',
        'https://vwwave-app.test.recognize.hosting/deeplink/',
    ],
    whitelistedDomains: [
        'vwwave.test.recognize.hosting',
        'vwwave-app.test.recognize.hosting',
    ],
    privacyPolicyUrl: 'https://www.volkerwessels.com/privacyverklaring_WAVE-app',
    instructionFilmUrl: 'https://www.volkerwessels.com/veiligheid/wave_app',
    applicationInsights: {
        instrumentationKey: 'e2341cb7-8b7d-460a-a125-9908b9f0f04a',
    },
};
